<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Session </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    label="Session"
                    v-model.trim="session"
                    @keyup.enter="saveSessionBtn"
                    :rules="[(v) => !!v || 'Session is required']"
                    clearable
                    :error-messages="error_msgs"
                    :loading="loading"
                    loader-height="2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    large
                    :loading="loading"
                    @click.enter="saveSessionBtn"
                    class="mx-2"
                    color="primary"
                  >
                    save session
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="editSessionForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.session }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeSessionBtn(item.id, item.session)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                Session available</span
              >
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Response v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { computed, provide, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { Response },
    setup() {
      const { saveSession, getSession, removeSession } = useActions([
        "saveSession",
        "getSession",
        "removeSession",
      ]);

      const { getters_session } = useGetters(["getters_session"]);

      const form = ref(null);

      const sessionForm = reactive({
        session: null,
        error_msgs: "",
        basket: {},
        valid: false,
        loading: false,
      });

      const editSessionForm = reactive({
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        headers: [
          { text: "#", value: "index" },
          {
            text: "SESSION",
            value: "session",
          },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        color: "",
      });

      let { isLoaded, color, msgHeader, msgBody, msgIcon, deleteResponse } =
        toRefs(editSessionForm);

      let { session, basket, valid, loading, error_msgs } = toRefs(sessionForm);

      const removeSessionBtn = (id, item) => {
        deleteResponse.value = false;

        removeSession(id).catch((e) => {
          deleteResponse.value = true;
          color.value = "error";
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          if (e.response.status === 403) {
            msgBody.value = "This action is unauthorized";
          } else {
            msgBody.value = `${item} + " cannot be deleted. It is used in other component(s)"`;
          }
        });
      };

      getSession().then(() => {
        isLoaded.value = false;
      });

      const saveSessionBtn = () => {
        valid.value = true;
        basket.value = {
          session: session.value,
        };

        if (form.value.validate()) {
          loading.value = true;
          saveSession(basket.value)
            .then(() => {
              loading.value = false;
              form.value.reset();
              valid.value = false;
            })
            .catch((e) => {
              loading.value = false;
              valid.value = false;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                deleteResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msgs.value = session.value + " already exists";
              }
            });
        }
        valid.value = false;
      };

      provide("color", color);

      const basketWithIndex = computed(() => {
        return getters_session.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(sessionForm),
        ...toRefs(editSessionForm),
        saveSessionBtn,
        basketWithIndex,
        removeSessionBtn,
        form,
      };
    },
  };
</script>
