<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card flat>
                <v-card-title primary-title>
                  Set Current Academic Calendar
                </v-card-title>
                <v-divider></v-divider>

                <v-row class="mt-2" justify="center" align="center">
                  <v-col cols="12" lg="3">
                    <v-text-field
                      outlined
                      class="rounded-0"
                      label="Start Year"
                      v-model.number="startyear"
                      type="number"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      outlined
                      label="End Year"
                      v-model.number="endyear"
                      class="rounded-0"
                      @keyup="checkYear"
                      type="number"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-switch
                      inset
                      disabled
                      :label="`${c_calendar ? 'Current Calendar' : ''}`"
                      v-model="c_calendar"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-btn
                      floating
                      large
                      :disabled="!valid"
                      @click="saveCalendarBtn"
                      class="mx-2"
                      color="primary"
                    >
                      save calendar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form ref="editCalendarForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:[`item.current`]="{ item }">
              <v-icon v-if="item.current" color="success">
                mdi-check-bold
              </v-icon>
            </template>

            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                No Academic Calendar available
              </span>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay absolute v-model="overlay">
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import {
    computed,
    getCurrentInstance,
    provide,
    reactive,
    ref,
    toRefs,
  } from "vue";
  import debounce from "@/debounce/debounce";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { Response },
    setup() {
      const vm = getCurrentInstance();
      const { saveCalendar, getCalendar, removeCalendar, signOut } = useActions([
        "saveCalendar",
        "getCalendar",
        "signIn",
        "removeCalendar",
        "signOut",
      ]);

      const { getters_calendar } = useGetters(["getters_calendar", "user"]);
      const form = ref(null);
      const calendarForm = reactive({
        valid: true,
        startyear: null,
        endyear: null,
        amount: null,
        error_msgs: [],
        basket: {},
        c_calendar: true,
        isCurrent: true,
        boolResponse: true,
        overlay: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        response: false,
        color: "",
      });

      const editCalendarForm = reactive({
        isLoaded: true,
        headers: [
          { text: "#", value: "index" },
          {
            text: "START YEAR",
            value: "startyear",
            align: "center",
          },
          { text: "END YEAR", value: "endyear", align: "center" },
          { text: "CURRENT", value: "current", align: "center" },
          //{ text: "Actions", value: "actions", sortable: false, align: "center" },
        ],
      });

      let {
        boolResponse,
        overlay,
        startyear,
        basket,
        endyear,
        error_msgs,
        c_calendar,
        msgHeader,
        msgBody,
        msgIcon,
        response,
        color,
      } = toRefs(calendarForm);

      const removeCalendarBtn = (id) => {
        response.value = false;
        removeCalendar(id)
          .then(() => {
            form.valid.reset();
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;

              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            overlay.value = false;
          });
      };

      provide("color", color);

      getCalendar().then(() => {
        editCalendarForm.isLoaded = false;
        boolResponse.value = getters_calendar.value.some(
          (element) =>
            element.startyear === new Date().getFullYear() &&
            element.current === true
        );
      });

      const saveCalendarBtn = () => {
        basket.value = {
          startyear: startyear.value,
          endyear: endyear.value,
          current: c_calendar.value,
        };

        if (form.value.validate()) {
          overlay.value = true;

          saveCalendar(basket.value)
            .then(() => {
              c_calendar.value = true;
              boolResponse.value = true;
              overlay.value = false;
            })
            .catch((e) => {
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            })
            .finally(() => {
              overlay.value = false;
            });
        }
      };

      const checkYear = debounce(() => {
        startyear.value >= endyear.value
          ? error_msgs.value.push("End year should be greater than start year...")
          : (error_msgs.value = []);
      });

      const basketWithIndex = computed(() => {
        return getters_calendar.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(calendarForm),
        ...toRefs(editCalendarForm),
        saveCalendarBtn,
        checkYear,
        basketWithIndex,
        form,
        removeCalendarBtn,
      };
    },
  };
</script>
