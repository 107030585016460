<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Staff Division </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="rounded-0"
                    label="Division"
                    v-model.trim="division"
                    :rules="[(v) => !!v || 'Staff division is required']"
                    clearable
                    :loading="loading"
                    loader-height="2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select
                    outlined
                    multiple
                    hide-details="auto"
                    class="rounded-0"
                    label="Staff Category"
                    :items="getters_staff_categories"
                    item-text="category"
                    item-value="id"
                    v-model="category"
                    :rules="[(v) => !!v || 'Staff Category is required']"
                    clearable
                    :loading="loading"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-btn
                    large
                    :loading="loading"
                    @click.enter="saveDivisionBtn"
                    class="mx-2"
                    color="primary"
                  >
                    save category
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form ref="validateForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template #item.category="{ item }">
              <v-edit-dialog
                large
                @save="saveDivisionChangesBtn(item.id)"
                @open="openCourseProgram(item)"
              >
                <v-btn depressed color="success">View Category Details</v-btn>

                <template #input>
                  <v-select
                    chips
                    deletable-chips
                    :menu-props="{ top: true, offsetY: true }"
                    class="pt-3"
                    outlined
                    full-width
                    multiple
                    :items="getters_staff_categories"
                    item-text="category"
                    item-value="id"
                    label="Category"
                    placeholder="Category"
                    v-model="basket"
                    :rules="[
                      (v) => !!v || 'Category is required',
                      basket.length > 0 ||
                        'At Least One Category Must Be Selected',
                    ]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggle">
                        <v-list-item-action>
                          <v-icon
                            :color="basket.length > 0 ? 'indigo darken-4' : ''"
                          >
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template #append> </template>
                  </v-select>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.division }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeDivisionBtn(item.id, item.division)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                Staff Category available
              </span>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Response v-if="response" class="mb-3">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-row>
      <v-col>
        <v-overlay absolute :value="overlay">
          <v-progress-circular indeterminate :value="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    computed,
    getCurrentInstance,
    provide,
    reactive,
    ref,
    toRefs,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { Response },
    setup() {
      const vm = getCurrentInstance();
      const {
        saveDivision,
        getStaffCategory,
        deleteDivision,
        getStaffDivision,
        saveChangesDivision,
        signOut,
      } = useActions([
        "saveDivision",
        "getStaffCategory",
        "deleteDivision",
        "getStaffDivision",
        "saveChangesDivision",
        "signOut",
      ]);

      const { getters_staff_categories, getters_staff_divisions } = useGetters([
        "getters_staff_categories",
        "getters_staff_divisions",
      ]);

      Promise.all([getStaffDivision(), getStaffCategory()]).then(
        () => (isLoaded.value = false)
      );

      const form = ref(null);

      const divsionCreateForm = reactive({
        category: null,
        division: null,
        valid: false,
        loading: false,
      });

      const divisionForm = reactive({
        overlay: false,
        validateForm: null,
        adminResponse: false,
        isLoaded: true,
        basket: [],
        headers: [
          { text: "#", value: "index" },
          {
            text: "STAFF DIVISION",
            value: "division",
          },
          {
            text: "STAFF CATEGORY",
            value: "category",
          },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        response: false,
        color: "",
      });

      let {
        validateForm,
        isLoaded,
        color,
        msgHeader,
        msgBody,
        msgIcon,
        response,
        basket,
        overlay,
      } = toRefs(divisionForm);

      let { category, valid, loading, division } = toRefs(divsionCreateForm);

      const removeDivisionBtn = (id, item) => {
        response.value = false;
        overlay.value = true;
        deleteDivision(id)
          .finally(() => {
            overlay.value = false;
            response.value = true;
          })
          .catch((e) => {
            color.value = "error";
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            if (e.response.status === 401) {
              msgBody.value =
                "Category Does Not Exist Currently. Kindly Refresh The Page";
            } else {
              msgBody.value = `${
                item + " Cannot Be Deleted.It Is Used In Other Component(s)"
              }`;
            }
          });
      };

      provide("color", color);

      const saveDivisionBtn = () => {
        valid.value = true;
        response.value = false;
        if (form.value.validate()) {
          loading.value = true;
          saveDivision({ division: division.value, category: category.value })
            .then(() => {
              color.value = "success";
              msgHeader.value = "Success";
              msgIcon.value = "mdi-check-circle";
              msgBody.value = "Staff Division Is Successfully Saved";
            })
            .catch((e) => {
              color.value = "error";
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              switch (e.response.status) {
                case 403:
                  msgBody.value = e.response.data.message;
                  break;
                case 422:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value =
                    "TRY AGAIN LATER OR CALL THE SYSTEM ADMINISTRATOR";
                  break;
              }
              response.value = true;
            })
            .finally(() => {
              loading.value = false;
              valid.value = false;
              response.value = true;
              form.value.reset();
            });
        }
        valid.value = false;
      };

      const basketWithIndex = computed(() => {
        return getters_staff_divisions.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      const selectAll = computed(() => {
        return getters_staff_categories.value.length === basket.value.length;
      });

      const selectSome = computed(() => {
        return (
          basket.value.length > 0 &&
          basket.value.length < getters_staff_divisions.value.length
        );
      });

      const icon = computed(() => {
        if (selectAll.value) return "mdi-checkbox-marked";
        if (selectSome.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const toggle = () => {
        if (selectAll.value) {
          basket.value = [];
        } else {
          basket.value = [];
          basket.value = getters_staff_categories.value.map((item) => item.id);
          //console.log(basket.value);
        }
      };

      const saveDivisionChangesBtn = (division_id) => {
        response.value = false;
        if (validateForm.value.validate()) {
          let newchanges = {
            division: division_id,
            category: basket.value,
          };
          isLoaded.value = true;
          saveChangesDivision(newchanges)
            .then(() => {
              response.value = true;
              msgBody.value = "Course is successfully updated";
              msgHeader.value = "Success";
              msgIcon.value = "mdi-check-circle";
              color.value = "success";
            })
            .catch((e) => {
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value = "Try Again Later Or Call The Administrator";
                  break;
              }
            })
            .finally(() => {
              isLoaded.value = false;
            });
        } else {
          setTimeout(() => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            msgBody.value = "AT LEAST ONE CATEGORY SHOULD BE SELECTED";
          });
        }
      };

      const openCourseProgram = (item) => {
        basket.value = [];
        let division = getters_staff_divisions.value.find(
          ({ id }) => id === item.id
        );
        basket.value = division.category.map(({ id }) => id);
      };

      return {
        ...toRefs(divsionCreateForm),
        ...toRefs(divisionForm),
        saveDivisionBtn,
        basketWithIndex,
        removeDivisionBtn,
        form,
        getters_staff_categories,
        getters_staff_divisions,
        saveDivisionChangesBtn,
        openCourseProgram,
        toggle,
        icon,
      };
    },
  };
</script>
