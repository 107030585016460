<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Intake </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-autocomplete
                    :items="months"
                    item-text="intake"
                    outlined
                    class="rounded-0"
                    label="Intake Code"
                    v-model="intake"
                    return-object
                    :rules="[(v) => !!v || 'Intake is required']"
                    clearable
                    @keyup.enter="saveIntakeBtn"
                    :error-messages="error_msgs"
                    :loading="loading"
                    hide-details="auto"
                    loader-height="2"
                  ></v-autocomplete>
                </v-col>
                <v-col class="text-center" cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    large
                    :disabled="!valid"
                    @click="saveIntakeBtn"
                    class="mx-2 font-weight-bold"
                    color="primary"
                  >
                    Save Intake
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="editIntakeForm">
          <v-data-table
            :headers="headers"
            :items="getters_intake"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template #item.current="{ item }">
              <v-scroll-x-transition>
                <template>
                  <v-btn color="success" v-if="item.current" icon>
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                  <v-btn v-else icon @click="makeCurrent(item.id)"
                    ><v-icon>mdi-calendar</v-icon></v-btn
                  >
                </template>
              </v-scroll-x-transition>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.intake }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeIntakeBtn(item.id, item.intake)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold"
                >No Academic Intake available</span
              >
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { provide, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { Response },
    setup() {
      const { saveIntake, getIntake, removeIntake, setCurrentIntake } =
        useActions([
          "saveIntake",
          "getIntake",
          "removeIntake",
          "setCurrentIntake",
        ]);

      const { getters_intake } = useGetters(["getters_intake"]);

      const intakeForm = reactive({
        intake: { intake_code: null, intake: null },
        loading: false,
        valid: true,
        error_msgs: "",
        months: [
          { intake_code: 1, intake: "January" },
          { intake_code: 2, intake: "February" },
          { intake_code: 3, intake: "March" },
          { intake_code: 4, intake: "April" },
          { intake_code: 5, intake: "May" },
          { intake_code: 6, intake: "June" },
          { intake_code: 7, intake: "July" },
          { intake_code: 8, intake: "August" },
          { intake_code: 9, intake: "September" },
          { intake_code: 10, intake: "October" },
          { intake_code: 11, intake: "November" },
          { intake_code: 12, intake: "December" },
        ],
      });

      const editIntakeForm = reactive({
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        adminResponse: false,
        headers: [
          /*   { text: "#", value: "index" }, */
          {
            text: "INTAKE CODE",
            value: "intake_code",
            /* align: "center", */
          },
          {
            text: "INTAKE MONTH",
            value: "intake",
          },
          { text: "CURRENT", value: "current" },
          { text: "ACTIONS", value: "actions", sortable: false, align: "right" },
        ],
        response: false,
        msgHeader: "",
        msgBody: "",
        intakeItem: "",
        color: "",
        msgIcon: "",
      });

      const form = ref(null);

      let { response, msgHeader, msgBody, msgIcon, color } =
        toRefs(editIntakeForm);

      const removeIntakeBtn = (id, item) => {
        response.value = false;
        removeIntake(id)
          .catch((e) => {
            color.value = "error";
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            switch (e.response.status) {
              case 403:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                break;
              default:
                msgBody.value = `${item} cannot be deleted. It is used in other component(s)`;
                break;
            }
          })
          .finally(() => (response.value = true));
      };

      provide("color", color);

      getIntake()
        .then(() => {
          editIntakeForm.isLoaded = false;
        })
        .catch((e) => {
          color.value = "error";
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          response.value = true;
          switch (e.response.status) {
            case 403:
              msgBody.value = e.response.data.message;
              break;
            case 423:
              msgBody.value = e.response.data.message;
              break;
            default:
              msgBody.value = `Try Again Later Or Call The System Administrator`;
              break;
          }
        });

      const { intake, error_msgs, loading } = toRefs(intakeForm);

      const saveIntakeBtn = () => {
        error_msgs.value = "";
        if (form.value.validate() && intake.value.intake) {
          loading.value = true;
          saveIntake(intake.value)
            .then(() => {
              loading.value = false;
              form.value.reset();
            })
            .catch((e) => {
              color.value = "error";
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              switch (e.response.status) {
                case 403:
                  msgBody.value = e.response.data.message;
                  break;
                case 423:
                  msgBody.value = e.response.data.message;
                  break;
                default:
                  msgBody.value = `Try Again Later Or Call The System Administrator`;
                  break;
              }
            })
            .finally(() => (response.value = true));
        }
      };

      const makeCurrent = (id) => {
        setCurrentIntake(id).catch((e) => {
          color.value = "error";
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          response.value = true;
          switch (e.response.status) {
            case 403:
              msgBody.value = e.response.data.message;
              break;
            case 423:
              msgBody.value = e.response.data.message;
              break;
            default:
              msgBody.value = `Try Again Later Or Call The System Administrator`;
              break;
          }
        });
      };

      return {
        ...toRefs(intakeForm),
        ...toRefs(editIntakeForm),
        saveIntakeBtn,
        removeIntakeBtn,
        form,
        makeCurrent,
        getters_intake,
      };
    },
  };
</script>
