<template>
  <v-container fluid>
    <validation-observer ref="observer">
      <v-row align="center" justify="center">
        <v-col>
          <validation-observer ref="observer">
            <v-card outlined>
              <v-toolbar
                dense
                elevation="0"
                color="primary"
                class="secondary--text"
                dark
              >
                <v-toolbar-title>OFFICE HOLDERS</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent ref="form1">
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-autocomplete
                          outlined
                          :disabled="getters_organogram.length <= 0"
                          class="rounded-0"
                          label="Office"
                          :items="getters_organogram"
                          v-model="office_name"
                          item-text="office_name"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        >
                          <template #item="{ item }">
                            <span>
                              <b>
                                {{ `${item.office_type} of ` }}
                              </b>
                              {{ item.office_name }}
                            </span>
                          </template>
                          <template #selection="{ item }">
                            <span>
                              <b class="error--text">
                                {{ `${item.office_type} of ` }}
                              </b>
                              {{ item.office_name }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Holder"
                        rules="required"
                      >
                        <v-autocomplete
                          outlined
                          class="rounded-0"
                          label="Holder"
                          :items="getters_active_staff"
                          v-model="holder"
                          item-text="title_name"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-autocomplete>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="4">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Start Date"
                            rules="required"
                          >
                            <v-text-field
                              v-model="startDate"
                              label="Start Date"
                              class="rounded-0"
                              readonly
                              outlined
                              hide-details="auto"
                              :error-messages="errors"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="startDate"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Status"
                        rules="required"
                      >
                        <v-switch
                          disabled
                          outlined
                          :label="`Status: ${status.toString()}`"
                          v-model="status"
                          :error-messages="errors"
                          hide-details="auto"
                          inset
                        >
                        </v-switch>
                      </validation-provider>
                    </v-col> -->
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </validation-observer>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            color="success"
            :loading="loading"
            @click="sendData"
            class="font-weight-bold"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="getters_office_holders"
          class="elevation-1"
          item-key="id"
          :loading="loadHolders"
          hide-default-footer
        >
          <template #item.organogram="{ value }">
            <span>
              <b>
                {{ `${value.office_type} of ` }}
              </b>
              {{ value.office_name }}
            </span>
          </template>
          <template #item.start_date="{ value }">
            {{ `${moment(value).format("LL")}` }}
          </template>
          <template #item.status="{ value }">
            <template v-if="value">
              <v-switch
                readonly
                v-model="statusSuccess"
                value="success"
                color="success"
              ></v-switch>
            </template>
            <template v-else>
              <v-switch
                readonly
                v-model="statusError"
                value="error"
                color="error"
              ></v-switch>
            </template>
          </template>

          <!-- <template #item.actions="{ item }">
            <v-menu
              offset-y
              open-on-hover
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list
                dark
                color="primary darken-2"
                min-width="250"
                tile
                class="py-0"
              >
                <v-list-item-group>
                  <v-list-item link @click="editPositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item link @click="deletePositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { provide, reactive, toRefs } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import {
    required,
    numeric,
    alpha,
    alpha_spaces,
    min_value,
    required_if,
  } from "vee-validate/dist/rules";

  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return parseInt(value) + parseInt(target) !== 100;
    },
    message: "Both Fields Should Add Up To 100%",
  });

  extend("required", {
    ...required,
    message: "{_field_} is required",
  });

  extend("required_if", {
    ...required_if,
    message: "{_field_} is required",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} is required",
  });

  extend("alpha", {
    ...alpha,
  });

  extend("numeric", {
    ...numeric,
  });

  extend("min_value", {
    ...min_value,
  });

  export default {
    components: {
      Response,
      ValidationProvider,
      ValidationObserver,
    },
    setup() {
      const { getActiveStaff, getOrganogram, saveOfficeHolder, getOfficeHolder } =
        useActions([
          "getActiveStaff",
          "getOrganogram",
          "saveOfficeHolder",
          "getOfficeHolder",
        ]);

      const { getters_active_staff, getters_organogram, getters_office_holders } =
        useGetters([
          "getters_active_staff",
          "getters_organogram",
          "getters_office_holders",
        ]);

      const organogramForm = reactive({
        showComponent: false,
        statusError: "error",
        statusSuccess: "success",
        componentCurrent: null,
        data: {},
        status: true,
        office_name: null,
        startDate: null,
        holder: null,
        menu: false,
        actionResponse: false,
        observer: null,
        overlay: false,
        color: null,
        msgBody: null,
        msgIcon: null,
        msgHeader: null,
        loadHolders: true,
        loading: false,
        form1: null,
        id: 0,
        headers: [
          { text: "NAME", value: "organogram" },
          { text: "HOLDER", value: "user.title_name" },
          { text: "START DATE", value: "start_date" },
          { text: "STATUS", value: "status" },
        ],
      });

      Promise.all([getActiveStaff(), getOrganogram(), getOfficeHolder()]).then(
        () => (loadHolders.value = false)
      );

      const {
        showComponent,
        componentCurrent,
        form1,
        data,
        office_name,
        holder,
        startDate,
        observer,
        color,
        msgBody,
        msgIcon,
        actionResponse,
        msgHeader,
        loading,
        loadHolders,
        id,
        // classification,
      } = toRefs(organogramForm);

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading.value = true;

            saveOfficeHolder({
              id: id.value,
              organogram_id: office_name.value,
              user_id: holder.value,
              start_date: startDate.value,
            })
              .then(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "success";
                msgBody.value = "Grade Is Successfully Saved.";
                msgIcon.value = "mdi-check-circle";
                msgHeader.value = "Saved";
              })
              .catch(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
              })
              .finally(() => {
                loading.value = false;
                form1.value.reset();
              });
          }
        });
      };

      provide("color", color);

      const editPositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "EditPosition";
        showComponent.value = true;
      };

      const deletePositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "DeletePosition";
        showComponent.value = true;
      };

      provide("office_name", data);

      return {
        ...toRefs(organogramForm),
        getters_active_staff,
        getters_organogram,
        sendData,
        editPositionBtn,
        deletePositionBtn,
        getters_office_holders,
      };
    },
  };
</script>
