<template>
  <v-container fluid>
    <validation-observer ref="observer">
      <v-row>
        <v-col>
          <validation-observer ref="observer">
            <v-card outlined>
              <v-toolbar
                dense
                elevation="0"
                color="primary"
                class="secondary--text"
                dark
              >
                <v-toolbar-title>Grade</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent>
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="5">
                      <validation-provider
                        v-slot="{ errors }"
                        name="School"
                        rules="required|alpha"
                      >
                        <v-text-field
                          outlined
                          label="School Name"
                          placeholder="School Name"
                          v-model="name"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Percentage"
                        rules="required|numeric|distinct:@Exams|min_value:1"
                      >
                        <v-text-field
                          outlined
                          label="Class"
                          v-model.number="classPercent"
                          :error-messages="errors"
                          hide-details="auto"
                          append-icon="mdi-percent-outline"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Exams"
                        rules="required|numeric|distinct:@Percentage"
                      >
                        <v-text-field
                          outlined
                          disabled
                          label="Exams"
                          v-model.number="examsPercent"
                          :error-messages="errors"
                          hide-details="auto"
                          append-icon="mdi-percent-outline"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Exams"
                        rules="required"
                      >
                        <v-select
                          outlined
                          :items="['Yes', 'No']"
                          label="Grade Point"
                          v-model="grade_point"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </validation-observer>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined>
            <v-toolbar
              elevation="0"
              color="primary"
              class="secondary--text"
              dark
              dense
            >
              <v-toolbar-title>
                Enter Grades From Highest to Lowest
              </v-toolbar-title>
            </v-toolbar>

            <v-slide-y-transition class="py-0" group tag="v-list">
              <template v-for="(task, i) in grades">
                <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>

                <v-list-item class="py-3" :key="`${i}-name`">
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|numeric|min_value:0"
                      >
                        <v-text-field
                          outlined
                          type="number"
                          hide-details="auto"
                          label="From"
                          v-model.number="task.from"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|numeric"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          type="number"
                          v-model.number="task.to"
                          :error-messages="errors"
                          label="To"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.grade"
                        :rules="{
                          required: true,
                          max: 2,
                          regex: /^(?:[A-Z]+[+-\w]*)$/,
                        }"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          v-model.trim="task.grade"
                          :error-messages="errors"
                          label="Grade"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" :lg="grade_point == 'Yes' ? 2 : 4">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.interpretation"
                        :rules="{
                          required: true,
                          regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                        }"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          v-model.trim="task.interpretation"
                          :error-messages="errors"
                          label="Interpretation"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="2" v-if="grade_point == 'Yes'">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.grade"
                        rules="required"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          v-model.trim="task.grade_point"
                          :error-messages="errors"
                          label="Grade Point"
                          placeholder="Grade Point"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="2" class="text-center">
                      <v-slide-y-reverse-transition>
                        <v-btn
                          icon
                          dark
                          v-if="i + 1 === grades.length"
                          color="success"
                          @click="newRowGrade()"
                        >
                          <v-icon dark>mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-slide-y-reverse-transition>
                      <v-slide-x-reverse-transition>
                        <v-btn
                          v-if="i + 1 >= 2 && i + 1 === grades.length"
                          dark
                          icon
                          color="error"
                          @click="removeRowGrade(i)"
                        >
                          <v-icon dark> mdi-delete</v-icon>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
            </v-slide-y-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined>
            <v-toolbar
              elevation="0"
              color="primary"
              class="secondary--text"
              dark
              dense
            >
              <v-toolbar-title>
                Enter Grades From Highest to Lowest
              </v-toolbar-title>
            </v-toolbar>

            <v-slide-y-transition class="py-0" group tag="v-list">
              <template v-for="(award, i) in awards">
                <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>

                <v-list-item class="py-3" :key="`${i}-name`">
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|numeric|min_value:0"
                      >
                        <v-text-field
                          outlined
                          type="number"
                          hide-details="auto"
                          label="From"
                          v-model.number="award.from"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|numeric"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          type="number"
                          v-model.number="award.to"
                          :error-messages="errors"
                          label="To"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="award.interpretation"
                        :rules="{
                          required: true,
                          regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                        }"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          v-model.trim="award.interpretation"
                          :error-messages="errors"
                          label="Interpretation"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="2" class="text-center">
                      <v-slide-y-reverse-transition>
                        <v-btn
                          icon
                          dark
                          v-if="i + 1 === grades.length"
                          color="success"
                          @click="newRowAward()"
                        >
                          <v-icon dark>mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-slide-y-reverse-transition>
                      <v-slide-x-reverse-transition>
                        <v-btn
                          v-if="i + 1 >= 2 && i + 1 === grades.length"
                          dark
                          icon
                          color="error"
                          @click="removeRowAward(i)"
                        >
                          <v-icon dark> mdi-delete</v-icon>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
            </v-slide-y-transition>
          </v-card>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col>
        <v-btn color="success" @click="sendData" class="font-weight-bold">
          save
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Grades </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { provide, reactive, toRefs, watch } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import { useActions } from "vuex-composition-helpers";
  import { required, numeric, alpha, min_value } from "vee-validate/dist/rules";
  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return parseInt(value) + parseInt(target) !== 100;
    },
    message: "Both Fields Should Add Up To 100%",
  });

  extend("required", {
    ...required,
    message: "{_field_} is required",
  });

  extend("alpha", {
    ...alpha,
  });

  extend("numeric", {
    ...numeric,
  });

  extend("min_value", {
    ...min_value,
  });

  export default {
    components: { Response, ValidationProvider, ValidationObserver },
    setup() {
      const gradeForm = reactive({
        classPercent: null,
        examsPercent: null,
        actionResponse: false,
        name: null,
        grades: [
          {
            from: null,
            to: null,
            grade: null,
            interpretation: null,
            grade_point: 0,
          },
        ],
        awards: [
          {
            from: null,
            to: null,
            interpretation: null,
          },
        ],
        valid: false,
        observer: null,
        overlay: false,
        color: null,
        msgBody: null,
        msgIcon: null,
        msgHeader: null,
        grade_point: "No",
      });

      const {
        examsPercent,
        classPercent,
        grades,
        observer,
        name,
        color,
        msgBody,
        msgIcon,
        actionResponse,
        msgHeader,
        awards,
      } = toRefs(gradeForm);

      const { saveGrades } = useActions(["saveGrades"]);

      watch(
        () => classPercent.value,
        (percent) => (examsPercent.value = 100 - percent)
      );

      const newRowGrade = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            grades.value.push({
              from: null,
              to: null,
              grade: null,
              interpretation: null,
              grade_point: 0,
            });
          }
        });
      };

      const newRowAward = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            awards.value.push({
              from: null,
              to: null,
              interpretation: null,
            });
          }
        });
      };

      const removeRowGrade = (i) => {
        grades.value.splice(i, 1);
      };

      const removeRowAward = (i) => {
        grades.value.splice(i, 1);
      };

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            const data = {
              grades: JSON.stringify(grades.value),
              awards: JSON.stringify(awards.value),
              name: name.value,
              class: classPercent.value,
              exams: examsPercent.value,
            };

            saveGrades(data)
              .then(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "success";
                msgBody.value = "Grade Is Successfully Saved.";
                msgIcon.value = "mdi-check-circle";
                msgHeader.value = "Saved";
                classPercent.value = null;
                examsPercent.value = null;
                name.value = null;
                grades.value = [
                  {
                    from: null,
                    to: null,
                    grade: null,
                    interpretation: null,
                    grade_point: 0,
                  },
                ];
              })
              .catch(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };

      provide("color", color);

      return {
        ...toRefs(gradeForm),
        newRowGrade,
        newRowAward,
        removeRowGrade,
        removeRowAward,
        sendData,
      };
    },
  };
</script>
