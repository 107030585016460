<template>
  <v-container fluid fill-height>
    <p class="text-uppercase grey--text">
      <span class="font-weight-light">NEW </span> SETUP
    </p>

    <v-row style="height: 100%">
      <v-item-group
        active-class="secondary lighten-4 "
        v-model="window"
        class="shrink mx-2"
        mandatory
        tag="v-flex"
      >
        <v-card>
          <v-item
            v-for="(item, i) in headerDetails"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <div>
              <v-btn
                tile
                block
                active-class="font-weight-bold"
                class="mb-0 primary--text d-flex justify-space-between"
                plain
                :input-value="active"
                @click="toggle"
              >
                {{ item.name }}
              </v-btn>
            </div>
          </v-item>
        </v-card>
      </v-item-group>

      <v-col class="white elevation-2 rounded">
        <v-window v-model="window" class="elevation-0 fill-height" vertical>
          <v-window-item v-for="(item, i) in headerDetails" :key="i">
            <component :is="item.componentname"></component>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { reactive, toRefs } from "vue";
  import Certificate from "@/components/Setup/Certificate";
  import Academic from "@/components/Setup/Academic";
  import Semester from "@/components/Setup/Semester";
  import Level from "@/components/Setup/Level";
  import Intake from "@/components/Setup/Intake";
  import Session from "@/components/Setup/Session";
  import Category from "@/components/Setup/Category";
  import Campus from "@/components/Setup/Campus";
  import StudentStatus from "@/components/Setup/StudentStatus";
  import Grade from "@/components/Setup/Grade";
  import Position from "@/components/Setup/Position";
  // import Leave from "@/components/Setup/Leave";
  import Division from "@/components/Setup/Division";
  import Organogram from "@/components/Setup/Organogram";
  import OfficeHolder from "@/components/Setup/OfficeHolder";

  export default {
    components: {
      cert: Certificate,
      academic: Academic,
      semester: Semester,
      level: Level,
      intake: Intake,
      session: Session,
      campus: Campus,
      status: StudentStatus,
      grade: Grade,
      position: Position,
      category: Category,
      // leave: Leave,
      division: Division,
      organogram: Organogram,
      OfficeHolder: OfficeHolder,
    },
    setup() {
      const setupComponents = reactive({
        headerDetails: [
          { name: "Academic Calendar", componentname: "academic" },
          { name: "Semester", componentname: "semester" },
          { name: "Level", componentname: "level" },
          { name: "Intake", componentname: "intake" },
          { name: "Certificate", componentname: "cert" },
          { name: "Session", componentname: "session" },
          { name: "Campus", componentname: "campus" },
          // { name: "Leave", componentname: "leave" },
          { name: "Student Status", componentname: "status" },
          { name: "Grading Systems", componentname: "grade" },
          { name: "Staff Categories", componentname: "category" },
          { name: "Staff Positions", componentname: "position" },
          { name: "Staff Divisions", componentname: "division" },
          { name: "Organogram", componentname: "organogram" },
          { name: "OfficeHolder", componentname: "OfficeHolder" },
        ],
        window: 0,
      });

      return {
        ...toRefs(setupComponents),
      };
    },
  };
</script>
