<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Semester </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="5" md="5" lg="6">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    label="Semester"
                    v-model="semester"
                    @keyup.enter="saveSemesterBtn"
                    :rules="[(v) => !!v || 'Semester is required']"
                    clearable
                    :error-messages="error_msgs"
                    :loading="loading"
                    loader-height="2"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    large
                    :disabled="!valid"
                    @click.enter="saveSemesterBtn"
                    class="mx-2"
                    color="primary"
                  >
                    save semester
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="editSemesterForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.semester }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeSemesterBtn(item.id, item.semester)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold"
                >No Academic Semester available</span
              >
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import Response from "@/components/ActionResponse/Response";
  import { computed, provide, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";

  export default {
    components: { Response },
    setup() {
      const { saveSemester, getSemester, removeSemester, saveSemesterChanges } =
        useActions([
          "saveSemester",
          "getSemester",
          "removeSemester",
          "saveSemesterChanges",
        ]);

      const { getters_semester, getters_abilities } = useGetters([
        "getters_semester",
        "getters_abilities",
      ]);

      const form = ref(null);

      const semesterForm = reactive({
        semester: null,
        basket: {},
        error_msgs: "",
        loading: false,
        semesterItem: null,
        valid: true,
      });

      const editSemesterForm = reactive({
        isLoaded: true,
        headers: [
          { text: "#", value: "index" },
          { text: "SEMESTER", value: "semester" },
          {
            text: getters_abilities.value.includes("delete_semester_access")
              ? "ACTIONS"
              : "",
            value: "actions",
            sortable: false,
          },
        ],
        newSemesterText: "",
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
      });

      let {
        isLoaded,
        newSemesterText,
        msgHeader,
        msgBody,
        msgIcon,
        actionResponse,
      } = toRefs(editSemesterForm);

      let { semester, basket, error_msgs, loading, semesterItem } =
        toRefs(semesterForm);

      const removeSemesterBtn = (id, item) => {
        actionResponse.value = false;
        semesterItem.value = item;
        removeSemester(id).catch((e) => {
          actionResponse.value = true;

          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          if (e.response.status === 403) {
            msgBody.value = "This action is unauthorized";
          } else {
            msgBody.value =
              semesterItem.value +
              " cannot be deleted.It is used in other component(s)";
          }
        });
      };

      getSemester().then(() => {
        isLoaded.value = false;
      });

      const saveSemesterBtn = () => {
        basket.value = {
          semester: semester.value,
        };

        if (form.value.validate()) {
          loading.value = true;
          saveSemester(basket.value)
            .then(() => {
              loading.value = false;
              form.value.reset();
            })
            .catch((e) => {
              loading.value = false;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                actionResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msgs.value = semester.value + " already exists";
              }
            });
        }
      };

      watch(
        () => semester.value,
        () => {
          error_msgs.value = "";
        }
      );

      const basketWithIndex = computed(() => {
        return getters_semester.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      const saveSemesterChangesBtn = (item) => {
        if (form.value.validate()) {
          Object.assign(item, {
            newSemesterText: newSemesterText.value,
          });
          if (item.newSemesterText == item.semester) return;

          saveSemesterChanges(item);
        }
      };

      provide("semester", { semester: semesterItem });

      return {
        ...toRefs(semesterForm),
        ...toRefs(editSemesterForm),
        saveSemesterChangesBtn,
        saveSemesterBtn,
        basketWithIndex,
        removeSemesterBtn,
        getters_abilities,
        form,
      };
    },
  };
</script>
