<template>
  <v-container fluid>
    <v-card flat class="pa-4" v-if="!actionResponse">
      <validation-observer ref="observer">
        <v-card flat>
          <v-card-text>
            <v-form @submit.prevent ref="form1">
              <v-row justify="center" align="center">
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Position"
                    rules="required|alpha_spaces"
                  >
                    <v-text-field
                      outlined
                      class="rounded-0"
                      label="Position Title"
                      v-model="position"
                      :error-messages="errors"
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Staff Division"
                    rules="required"
                  >
                    <v-select
                      outlined
                      class="rounded-0"
                      label="Staff Division"
                      :items="getters_staff_divisions"
                      v-model="division"
                      item-text="division"
                      item-value="id"
                      :error-messages="errors"
                      hide-details="auto"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Staff Category"
                    rules="required"
                  >
                    <v-select
                      outlined
                      class="rounded-0"
                      label="Staff Category"
                      :items="getters_staff_categories"
                      v-model="category"
                      item-text="category"
                      item-value="id"
                      :error-messages="errors"
                      hide-details="auto"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Staff Category"
                    rules="required"
                  >
                    <v-select
                      outlined
                      class="rounded-0"
                      label="Classification"
                      :items="['Single', 'Multiple']"
                      v-model="classification"
                      :error-messages="errors"
                      hide-details="auto"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions v-if="!actionResponse">
            <v-footer app color="transparent">
              <v-btn color="success" @click="saveChanges">save changes</v-btn>
            </v-footer>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-card>

    <Alert v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
    </Alert>

    <v-overlay absolute :value="overlay" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import { defineComponent, inject, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    required,
    alpha_dash,
    alpha_spaces,
    numeric,
  } from "vee-validate/dist/rules";
  import Alert from "@/components/slots/Alert";

  extend("email", { ...numeric, message: "Email must be valid" });

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { ValidationProvider, ValidationObserver, Alert },
    setup() {
      const { editPosition } = useActions(["editPosition"]);
      const { getStaffDivision, getStaffCategory } = useActions([
        "getStaffDivision",
        "getStaffCategory",
      ]);

      const { getters_staff_divisions, getters_staff_categories } = useGetters([
        "getters_staff_divisions",
        "getters_staff_categories",
      ]);

      const positionInject = inject("position");

      const bankForm = reactive({
        position: null,
        category: null,
        division: null,
        classification: null,
        observer: null,
        overlay: true,
        actionResponse: false,
        type: "",
        msgBody: "",
      });

      const {
        type,
        msgBody,
        actionResponse,
        overlay,
        observer,
        position,
        category,
        division,
        classification,
      } = toRefs(bankForm);

      Promise.all([getStaffDivision(), getStaffCategory()]).then(() => {
        overlay.value = false;
        category.value = positionInject.value.staff_category_id;
        division.value = positionInject.value.staff_division_id;
        position.value = positionInject.value.position;
        classification.value = positionInject.value.classification;
      });

      const saveChanges = async () => {
        await observer.value.validate().then((result) => {
          overlay.value = true;
          actionResponse.value = false;
          if (result) {
            editPosition({
              staff_category_id: category.value,
              staff_division_id: division.value,
              position: position.value,
              classification: classification.value,
              id: positionInject.value.id,
            })
              .finally(() => {
                overlay.value = false;
                actionResponse.value = true;
              })
              .then(() => {
                actionResponse.value = true;
                type.value = "success";
                msgBody.value = "Position Is Successfully Updated...";
              })
              .catch((e) => {
                actionResponse.value = true;
                switch (e.response.status) {
                  case 404:
                    msgBody.value = e.response.data.message;
                    break;

                  case 403:
                    msgBody.value = e.response.data.message;
                    break;

                  default:
                    msgBody.value = "Something went wrong";
                    break;
                }
                type.value = "error";
              });
          }
        });
      };

      provide("type", type);

      return {
        ...toRefs(bankForm),
        saveChanges,
        getters_staff_divisions,
        getters_staff_categories,
      };
    },
  });
</script>
