<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Campus </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    label="Campus ID"
                    v-model.number="campusID"
                    :rules="[(v) => !!v || 'Campus ID is required']"
                    clearable
                    :loading="loading"
                    type="number"
                    loader-height="2"
                    hide-details="auto"
                    :error-messages="error_msgs"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    label="Campus Name"
                    v-model="campusName"
                    :rules="[(v) => !!v || 'Campus Name is required']"
                    clearable
                    :loading="loading"
                    loader-height="2"
                    :error-messages="error_msgs"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col class="text-center" cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    large
                    :disabled="clickOnce"
                    @click="saveCampusBtn"
                    class="mx-2"
                    color="primary"
                  >
                    save campus
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="editCampusForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.campus_name }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeCampusBtn(item.id, item.campus_name)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                No Academic Campus available
              </span>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>

<script>
  import { computed, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";

  export default {
    components: { Error },
    setup() {
      const { saveCampus, getCampus, removeCampus } = useActions([
        "saveCampus",
        "getCampus",
        "removeCampus",
      ]);

      const { getters_campus } = useGetters(["getters_campus"]);

      const campusForm = reactive({
        campusName: null,
        loading: false,
        basket: {},
        clickOnce: false,
        error_msgs: "",
        campusID: null,
      });

      const form = ref(null);

      const editCampusForm = reactive({
        valid: false,
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        headers: [
          { text: "#", value: "index" },
          {
            text: "CAMPUS ID",
            value: "campus_id",
            align: "left",
          },
          {
            text: "CAMPUS NAME",
            value: "campus_name",
            align: "left",
          },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
      });

      let { msgHeader, msgBody, msgIcon, deleteResponse } =
        toRefs(editCampusForm);

      let { campusName, basket, clickOnce, error_msgs, loading, campusID } =
        toRefs(campusForm);

      watch(
        () => campusName.value,
        () => (error_msgs.value = "")
      );

      const removeCampusBtn = (id, item) => {
        deleteResponse.value = false;

        removeCampus(id).catch((e) => {
          deleteResponse.value = true;

          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          if (e.response.status === 403) {
            msgBody.value = "This action is unauthorized";
          } else {
            msgBody.value =
              item + " cannot be deleted. It is used in other component(s)";
          }
        });
      };

      getCampus().then(() => {
        editCampusForm.isLoaded = false;
      });

      const saveCampusBtn = () => {
        error_msgs.value = "";
        clickOnce.value = true;
        basket.value = {
          campus_name: campusName.value,
          campus_id: campusID.value,
        };

        if (form.value.validate()) {
          loading.value = true;
          saveCampus(basket.value)
            .then(() => {
              loading.value = false;
              form.value.reset();
              clickOnce.value = false;
            })
            .catch((e) => {
              loading.value = false;
              clickOnce.value = false;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                deleteResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msgs.value = campusName.value + " already exists";
              }
            });
        }
        clickOnce.value = false;
      };

      const basketWithIndex = computed(() => {
        return getters_campus.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(campusForm),
        ...toRefs(editCampusForm),
        saveCampusBtn,
        basketWithIndex,
        removeCampusBtn,
        form,
      };
    },
  };
</script>
