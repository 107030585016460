<template>
  <v-alert text :type="type" :value="true">
    <div class="body-2"><slot name="body"></slot></div>
  </v-alert>
</template>

<script>
  import { defineComponent, inject, ref } from "vue";

  export default defineComponent({
    setup() {
      let response = ref(true);
      const type = inject("type");

      return { response, type };
    },
  });
</script>
