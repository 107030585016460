<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Certificate </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-row justify="center" align="center">
                <v-col cols="12" lg="3">
                  <v-text-field
                    :error-messages="error_msg"
                    outlined
                    label="Certificate ID"
                    clearable
                    v-model="certificate_id"
                    :rules="[(v) => !!v || 'Certificate ID is required']"
                    min="0"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field
                    :error-messages="error_msg"
                    outlined
                    label="Certificate Shorthand"
                    clearable
                    v-model="certificate_shorthand"
                    :rules="[(v) => !!v || 'Certificate Shorthand is required']"
                    min="0"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    :error-messages="error_msg"
                    hide-details="auto"
                    outlined
                    clearable
                    label="Certificate Name"
                    v-model="certificate_name"
                    :rules="[(v) => !!v || 'Certificate Name is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="text-center" cols="12" lg="3">
                  <v-btn
                    @click="addCertificate"
                    :disabled="!valid"
                    large
                    class="mx-2"
                    color="primary"
                  >
                    SAVE CERTIFICATE
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-form ref="certificateHeaders">
            <v-data-table
              :headers="headers"
              :items="basketWithIndex"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-edit-dialog single-line>
                  <v-btn dark right large icon>
                    <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                  </v-btn>

                  <template v-slot:input>
                    <v-card flat>
                      <v-card-text class="pa-3 ma-0">
                        Do You Want to Delete
                        <b>{{ item.certificate_name }}</b> ?
                      </v-card-text>
                      <v-card-actions class="px-0 ma-0">
                        <v-btn
                          color="red"
                          dark
                          block
                          @click="
                            removeCertificateBtn(item.id, item.certificate_name)
                          "
                        >
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:no-data>
                <span class="subheading font-weight-bold"
                  >No certificate available</span
                >
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>
<script>
  import { computed, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";

  export default {
    components: { Error },
    setup() {
      let { saveCertificate, getCertificate, removeCertificate } = useActions([
        "saveCertificate",
        "getCertificate",
        "removeCertificate",
      ]);
      let { getters_certificate } = useGetters(["getters_certificate"]);

      getCertificate();

      const form = ref(null);

      let certificateForm = reactive({
        basket: [],
        certificate_shorthand: "",
        certificate_name: "",
        certificate_id: "",
        max25chars: (v) => v.length <= 25 || "Input too long!",
        valid: false,
        isadmin: true,
        error_msg: "",
        loading: false,
      });

      const {
        certificate_shorthand,
        certificate_id,
        certificate_name,
        isadmin,
        valid,
        error_msg,
        loading,
      } = toRefs(certificateForm);

      let certificateHeaders = reactive({
        headers: [
          { text: "#", value: "index" },
          { text: "CERTIFICATE ID", value: "certificate_id", align: "center" },
          {
            text: "CERTIFICATE ABBREVIATION",
            value: "certificate_shorthand",
            align: "center",
          },
          { text: "CERTIFICATE NAME", value: "certificate_name" },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        deleteResponse: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
      });

      const { deleteResponse, msgHeader, msgBody, msgIcon } =
        toRefs(certificateHeaders);

      const addCertificate = () => {
        const certBasket = {
          certificate_shorthand: certificate_shorthand.value,
          certificate_name: certificate_name.value,
          certificate_id: certificate_id.value,
        };

        if (form.value.validate()) {
          valid.value = false;
          deleteResponse.value = false;
          saveCertificate(certBasket)
            .then(() => {
              loading.value = false;
              form.value.reset();
              valid.value = false;
            })
            .catch((e) => {
              loading.value = false;
              valid.value = false;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                deleteResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msg.value = certificate_name.value + " already exists";
              }
            });
        }
      };

      const removeCertificateBtn = (shorthand, item) => {
        deleteResponse.value = false;
        removeCertificate(shorthand)
          .then(() => {
            isadmin.value = true;
          })
          .catch((e) => {
            deleteResponse.value = true;

            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            if (e.response.status === 403) {
              msgBody.value = "This action is unauthorized";
            } else {
              msgBody.value =
                item + " cannot be deleted. It is used in other component(s)";
            }
          });
      };

      const basketWithIndex = computed(() => {
        return getters_certificate.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(certificateForm),
        ...toRefs(certificateHeaders),
        addCertificate,
        removeCertificateBtn,
        basketWithIndex,
        form,
      };
    },
  };
</script>
