<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Staff Category </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="rounded-0"
                    label="Staff Category"
                    v-model.trim="category"
                    @keyup.enter="saveCategoryBtn"
                    :rules="[(v) => !!v || 'Staff Category is required']"
                    clearable
                    :loading="loading"
                    loader-height="2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    large
                    :loading="loading"
                    @click.enter="saveCategoryBtn"
                    class="mx-2"
                    color="primary"
                  >
                    save category
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="editSessionForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.category }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeCategoryBtn(item.id, item.category)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                Staff Category available</span
              >
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Response v-if="response" class="mb-3">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { computed, provide, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { Response },
    setup() {
      const { saveCategory, getStaffCategory, removeCategory } = useActions([
        "saveCategory",
        "getStaffCategory",
        "removeCategory",
      ]);

      const { getters_staff_categories } = useGetters([
        "getters_staff_categories",
      ]);

      getStaffCategory().then(() => {
        isLoaded.value = false;
      });

      const form = ref(null);

      const sessionForm = reactive({
        category: null,
        valid: false,
        loading: false,
      });

      const editSessionForm = reactive({
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        headers: [
          { text: "#", value: "index" },
          {
            text: "STAFF CATEGORY",
            value: "category",
          },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        response: false,
        color: "",
      });

      let { isLoaded, color, msgHeader, msgBody, msgIcon, response } =
        toRefs(editSessionForm);

      let { category, valid, loading } = toRefs(sessionForm);

      const removeCategoryBtn = (id, item) => {
        response.value = false;

        removeCategory(id).catch((e) => {
          response.value = true;
          color.value = "error";
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          if (e.response.status === 401) {
            msgBody.value =
              "Category Does Not Exist Currently. Kindly Refresh The Page";
          } else {
            msgBody.value = `${
              item + " Cannot Be Deleted.It Is Used In Other Component(s)"
            }`;
          }
        });
      };

      provide("color", color);

      const saveCategoryBtn = () => {
        valid.value = true;
        response.value = false;
        if (form.value.validate()) {
          loading.value = true;
          saveCategory(category.value)
            .then(() => {
              form.value.reset();
              /* color.value = "success";
                        msgHeader.value = "Success";
                        msgIcon.value = "mdi-check-circle";
                        msgBody.value = "Staff Category Is Successfully Saved"; */
            })
            .catch((e) => {
              color.value = "error";
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              switch (e.response.status) {
                case 403:
                  msgBody.value = "THIS ACTION IS UNAUTHORIZED";
                  break;
                case 422:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value =
                    "TRY AGAIN LATER OR CALL THE SYSTEM ADMINISTRATOR";
                  break;
              }
              response.value = true;
            })
            .finally(() => {
              loading.value = false;
              valid.value = false;
            });
        }
        valid.value = false;
      };

      const basketWithIndex = computed(() => {
        {return getters_staff_categories.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));}
      });

      return {
        ...toRefs(sessionForm),
        ...toRefs(editSessionForm),
        saveCategoryBtn,
        basketWithIndex,
        removeCategoryBtn,
        form,
      };
    },
  };
</script>
