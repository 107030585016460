<template>
  <v-container fluid>
    <validation-observer ref="observer">
      <v-row>
        <v-col>
          <validation-observer ref="observer">
            <v-card outlined>
              <v-toolbar
                dense
                elevation="0"
                color="primary"
                class="secondary--text"
                dark
              >
                <v-toolbar-title>STAFF POSITIONS</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent ref="form1">
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Position"
                        rules="required|alpha_spaces"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          label="Position Title"
                          v-model="position"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Staff Division"
                        rules="required"
                      >
                        <v-select
                          outlined
                          class="rounded-0"
                          @change="changeStaffDivision"
                          label="Staff Division"
                          :items="getters_staff_divisions"
                          v-model="division"
                          item-text="division"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Staff Category"
                        rules="required"
                      >
                        <v-select
                          outlined
                          :disabled="categoryBasket.length <= 0"
                          class="rounded-0"
                          label="Staff Category"
                          :items="categoryBasket"
                          v-model="category"
                          item-text="category"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Staff Category"
                        rules="required"
                      >
                        <v-select
                          outlined
                          class="rounded-0"
                          label="Classification"
                          :items="['Single', 'Multiple']"
                          v-model="classification"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </validation-observer>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            color="success"
            :loading="loading"
            @click="sendData"
            class="font-weight-bold"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="getters_staff_positions"
          class="elevation-1"
          item-key="id"
          :loading="loading"
          hide-default-footer
        >
          <template #item.actions="{ item }">
            <v-menu
              offset-y
              open-on-hover
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list
                dark
                color="primary darken-2"
                min-width="250"
                tile
                class="py-0"
              >
                <v-list-item-group>
                  <v-list-item link @click="editPositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item link @click="deletePositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!--Edit Position -->
    <v-navigation-drawer
      v-model="showComponent"
      fixed
      :permanent="showComponent"
      right
      class="shadow"
      clipped
      width="30%"
    >
      <v-toolbar class="mt-15 secondary--text" color="primary">
        <v-btn
          left
          class="ma-2 secondary--text"
          @click="showComponent = !showComponent"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-toolbar-title>STAFF POSITIONS</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn class="ma-2 secondary--text" icon @click="showComponent = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <component :is="componentCurrent" v-if="showComponent" />
    </v-navigation-drawer>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { getCurrentInstance, provide, reactive, toRefs } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import {
    required,
    numeric,
    alpha,
    alpha_spaces,
    min_value,
  } from "vee-validate/dist/rules";
  import EditPosition from "./EditPosition";
  import DeletePosition from "./DeletePosition";

  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return parseInt(value) + parseInt(target) !== 100;
    },
    message: "Both Fields Should Add Up To 100%",
  });

  extend("required", {
    ...required,
    message: "{_field_} is required",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} is required",
  });

  extend("alpha", {
    ...alpha,
  });

  extend("numeric", {
    ...numeric,
  });

  extend("min_value", {
    ...min_value,
  });

  export default {
    components: {
      Response,
      ValidationProvider,
      ValidationObserver,
      EditPosition,
      DeletePosition,
    },
    setup() {
      const vm = getCurrentInstance();
      const {
        getStaffDivision,
        /* getStaffCategory, */ getStaffPosition,
        signOut,
      } = useActions([
        "getStaffDivision",
        /* "getStaffCategory", */ "getStaffPosition",
        "signOut",
      ]);

      const {
        getters_staff_divisions,
        // getters_staff_categories,
        getters_staff_positions,
      } = useGetters([
        "getters_staff_divisions",
        // "getters_staff_categories",
        "getters_staff_positions",
      ]);

      const positionForm = reactive({
        showComponent: false,
        componentCurrent: null,
        data: {},
        position: null,
        category: null,
        division: null,
        actionResponse: false,
        valid: false,
        observer: null,
        overlay: false,
        color: null,
        msgBody: null,
        msgIcon: null,
        msgHeader: null,
        loading: true,
        classification: null,
        form1: null,
        headers: [
          { text: "POSITION", value: "position" },
          { text: "STAFF DIVISION", value: "staff_division.division" },
          { text: "STAFF CATEGORY", value: "staff_category.category" },
          { text: "POSITION CLASSIFICAITON", value: "classification" },
          { text: "ACTIONS", value: "actions" },
        ],
        categoryBasket: [],
      });

      Promise.all([
        getStaffDivision(),
        //getStaffCategory(),
        getStaffPosition(),
      ]).then(() => (loading.value = false));

      const {
        categoryBasket,
        showComponent,
        componentCurrent,
        form1,
        data,
        position,
        division,
        category,
        observer,
        color,
        msgBody,
        msgIcon,
        actionResponse,
        msgHeader,
        loading,
        classification,
      } = toRefs(positionForm);

      const { savePosition } = useActions(["savePosition"]);

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading.value = true;

            savePosition({
              position: position.value,
              staff_category_id: category.value,
              staff_division_id: division.value,
              classification: classification.value,
            })
              .then(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "success";
                msgBody.value = "Grade Is Successfully Saved.";
                msgIcon.value = "mdi-check-circle";
                msgHeader.value = "Saved";
              })
              .catch((e) => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "error";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                switch (e.response.status) {
                  case 403:
                  case 401:
                    msgBody.value = e.response.data.message;
                    break;
                  case 423:
                    msgBody.value = e.response.data.message;
                    signOut().then(() => {
                      vm.proxy.$router.replace({
                        name: "Login",
                      });
                    });
                    break;
                  default:
                    msgBody.value =
                      "Try Again Later or Call The System Administrator";
                    break;
                }
              })
              .finally(() => {
                loading.value = false;
                form1.value.reset();
              });
          }
        });
      };

      provide("color", color);

      const editPositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "EditPosition";
        showComponent.value = true;
      };

      const deletePositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "DeletePosition";
        showComponent.value = true;
      };

      const changeStaffDivision = (id) => {
        let basket = getters_staff_divisions.value.find((item) => item.id == id);
        categoryBasket.value = basket.category;
      };

      provide("position", data);

      return {
        ...toRefs(positionForm),
        getters_staff_divisions,
        // getters_staff_categories,
        getters_staff_positions,
        sendData,
        editPositionBtn,
        deletePositionBtn,
        changeStaffDivision,
      };
    },
  };
</script>
