<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title primary-title> Student Status </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    label="Status Name"
                    v-model="status"
                    :rules="[(v) => !!v || 'Status Name is required']"
                    class="rounded-0"
                    :loading="loading"
                    loader-height="2"
                    :error-messages="error_msgs"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    label="Status Duration"
                    class="rounded-0"
                    type="number"
                    v-model.number="duration"
                    min="0"
                    :rules="[
                      (v) =>
                        (v > -1 && v <= 4) || 'Status Duration is required',
                    ]"
                    clearable
                    :loading="loading"
                    loader-height="2"
                    :error-messages="error_msgs"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col class="text-center" cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    large
                    :disabled="!valid"
                    @click="saveStatusBtn"
                    class="mx-2 font-weight-bold"
                    color="primary"
                  >
                    save status
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="editStatusForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.status }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="removeStatusBtn(item.id, item.status)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                No Academic Status available
              </span>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>

<script>
  import { computed, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";

  export default {
    components: { Error },
    setup() {
      const { saveStudentStatus, getStudentStatus, removeStudentStatus } =
        useActions([
          "saveStudentStatus",
          "getStudentStatus",
          "removeStudentStatus",
        ]);

      const { getters_student_status } = useGetters(["getters_student_status"]);

      const form = ref(null);

      const statusForm = reactive({
        status: null,
        loading: false,
        basket: {},
        error_msgs: "",
        duration: null,
        valid: true,
      });

      const editStatusForm = reactive({
        isLoaded: true,
        headers: [
          { text: "#", value: "index" },
          {
            text: "STATUS",
            value: "status",
          },
          {
            text: "DURATION",
            value: "year",
            align: "center",
          },

          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
      });

      let { msgHeader, msgBody, msgIcon, deleteResponse } =
        toRefs(editStatusForm);

      let { status, basket, error_msgs, loading, duration } = toRefs(statusForm);

      watch(
        () => status.value,
        () => (error_msgs.value = "")
      );

      const removeStatusBtn = (id, item) => {
        deleteResponse.value = false;

        removeStudentStatus(id).catch((e) => {
          deleteResponse.value = true;

          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          if (e.response.status === 403) {
            msgBody.value = "This action is unauthorized";
          } else {
            msgBody.value = `${item} + " cannot be deleted.\
        					It is used in other component(s)"`;
          }
        });
      };

      getStudentStatus().then(() => {
        editStatusForm.isLoaded = false;
      });

      const saveStatusBtn = () => {
        error_msgs.value = "";
        basket.value = {
          status: status.value,
          year: duration.value,
        };

        if (form.value.validate()) {
          loading.value = true;
          saveStudentStatus(basket.value)
            .then(() => {
              loading.value = false;
              form.value.reset();
            })
            .catch((e) => {
              loading.value = false;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                deleteResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msgs.value = status.value + " already exists";
              }
            });
        }
      };

      const basketWithIndex = computed(() => {
        return getters_student_status.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(statusForm),
        ...toRefs(editStatusForm),
        saveStatusBtn,
        basketWithIndex,
        removeStatusBtn,
        form,
      };
    },
  };
</script>
