<template>
  <v-container fluid>
    <v-list v-if="!actionResponse">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 text-wrap">
            Do You Want To Delete
            <b>{{ position.position }}</b> from the database?
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <Alert v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
    </Alert>

    <v-footer app color="transparent" v-if="!actionResponse">
      <v-btn
        :loading="loading"
        color="error"
        @click="deletePositionBtn(position.id)"
      >
        Delete
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script>
  import { defineComponent, inject, provide, reactive, toRefs } from "vue";
  import { useActions } from "vuex-composition-helpers";
  import Alert from "@/components/slots/Alert";
  export default defineComponent({
    components: { Alert },
    setup() {
      const position = inject("position");
      const { deletePosition } = useActions(["deletePosition"]);

      const positionData = reactive({
        actionResponse: false,
        type: "",
        msgBody: "",
        loading: false,
      });

      const { loading, actionResponse, type, msgBody } = toRefs(positionData);

      const deletePositionBtn = (id) => {
        actionResponse.value = false;
        loading.value = true;
        deletePosition(id)
          .then(() => {
            actionResponse.value = true;
            type.value = "success";
            msgBody.value = "Position Is Successfully Deleted...";
          })
          .catch((e) => {
            actionResponse.value = true;
            switch (e.response.status) {
              case 404:
                msgBody.value = e.response.data.message;
                break;

              case 403:
                msgBody.value = e.response.data.message;
                break;

              default:
                msgBody.value = "Something went wrong";
                break;
            }
            type.value = "error";
          })
          .finally(() => {
            loading.value = false;
          });
      };

      provide("type", type);

      return {
        position,
        deletePositionBtn,
        ...toRefs(positionData),
      };
    },
  });
</script>
